<template>
  <section class="hero-bg-custom hero d-flex flex-column justify-content-center">
    <div class="container">
      <div class="hero-content row">
        <div class="col-lg-7 col-sm-12">
          <div class="text-white" style="margin-bottom: 30px">
            <h1 class="hero-title" style="margin-bottom: 16px">e-Jabatan</h1>
            <p class="hero-subtitle">
              Layanan Penyajian Informasi Jabatan sebagai Referensi kepada<br />
              Pencari Kerja dan Pemberi Kerja
            </p>
          </div>

          <div class="row mt-4 mb-3">
            <div class="search-bar big">
              <form novalidate role="form">
                <div class="input-group position-search left-bigger align-items-center bg-white">
                  <input v-model="quickSearchVal" type="text" placeholder="Cari informasi jabatan berdasarkan kata kunci"
                    class="form-control bg-white" name="keyword" formcontrolname="keyword"
                    id="keyword"
                  />
                  <div class="btn-wrapper input-group-append input-group-lg">
                    <button class="btn btn-ternary text-white" @click.prevent="quickSearch">Cari</button>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <span class="d-inline text-white">Ingin mencari Informasi Jabatan secara spesifik?
            <a
              class="d-inline text-decoration-none text-ternary fw-medium"
              @click="toAdvanceSearch"
              role="button"
            >
              Klik di sini!
            </a>
          </span>
        </div>
      </div>
    </div>

    <div class="container">
        <div class="hero-footer justify-content-center text-center d-lg-flex d-md-flex d-none">
            <p class="text-white align-content-center">
                Informasi lebih lanjut mengenai layanan informasi jabatan melalui:<br />
                Direktorat Bina Penempatan Tenaga Kerja Dalam Negeri<br />
                Direktorat Jenderal Pembinaan Penempatan Tenaga Kerja dan Perluasan Kesempatan Kerja<br />
                email:
                <a class="d-inline text-decoration-none text-ternary fw-medium" href="mailto:apbj@kemnaker.go.id"
                    role="button">
                    apbj@kemnaker.go.id
                </a>
            </p>
        </div>
        <div class="hero-footer d-flex d-lg-none d-md-none">
            <p class="text-white">
                Informasi lebih lanjut email:
                <a class="d-inline text-decoration-none text-ternary fw-medium" href="" role="button">
                    apbj@kemnaker.go.id
                </a>
            </p>
        </div>
    </div>
  </section>
</template>

<script>
import swal from 'sweetalert2'
import api from './search/api'
import auth from '../helpers/auth'
export default {
  data () {
    return {
      quickSearchVal: ''
    }
  },
  async created () {
    const codeLogin = this.$route.query.code
    const route = this.$route
    if (codeLogin) {
      // localStorage.setItem('codeNaco', codeLogin)
      await this.login(codeLogin)
    }
    console.log('codeLogin', codeLogin)
    console.log('route', route)
  },
  methods: {
    async quickSearch () {
      const swalRes = await swal.fire({
        title: 'Pilih Pencarian',
        confirmButtonText: 'KJN',
        showDenyButton: true,
        denyButtonText: 'KBJI'
        // showCancelButton: true,
        // cancelButtonText: 'KBJI'
      })
      console.log('swalRes', swalRes.isConfirmed, swalRes.isDenied)
      if (swalRes.isConfirmed) {
        this.$router.push(`/home/quick-search?keyword=${this.quickSearchVal}`)
      } else if (swalRes.isDenied) {
        this.$router.push(`/home/kjn_golongan?keyword=${this.quickSearchVal}`)
      }
    },
    toAdvanceSearch () {
      this.$router.push('/home/advance-search')
    },
    async login (code) {
      try {
        const res = await api.login({ code })
        auth.setLogin(res.user)
        auth.setSSOToken(res.sso_token)
        auth.setToken(res.token)
        if (res.user.is_active === '0') {
          this.$router.push('/profile')
        } else {
          this.$router.push('/')
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style>
  @import url('../assets/styles/hero.css');
  @import url('../assets/styles/quick-search.css');
</style>
